<template>
  <div class="Awarp">
    <dashCard class="operationAm1" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('operationAm1_workOverview','工单总览', getZEdata)}}
        <el-tooltip popper-class="tooltip_tips" effect="dark" placement="right">
          <div slot="content">{{commonLangHandler('operationAm1_timeFrame','1.逾期工单/未启动工单/已启动未完成工单的统计时间范围包含今年和往年未完成的数据;', getZEdata)}}<br/>
            {{commonLangHandler('operationAm1_overdueSentence','2.逾期工单: 工单的完成工单时间/当前时间>计划完成时间;', getZEdata)}}<br/>
            {{commonLangHandler('operationAm1_completed','3.完成工单:工单在已完成状态;', getZEdata)}}<br/>
           {{commonLangHandler('operationAm1_startedIncompleteSentence','4.已启动未完成工单: 工单在非草稿状态，且未完成;', getZEdata)}}<br/>
           {{commonLangHandler('operationAm1_unstartedSentence','5.未启动工单:工单在草稿状态，运维工程师未发起审批;', getZEdata)}}
          </div>
          <i class="el-icon-info textContant"></i>
        </el-tooltip>
      </template>
      <template slot="aside"></template>
      <div style="display: flex;flex-wrap: wrap; width: 100%;">
        <div v-for="(item,index) in newData" :key="index" style="width: 30%; margin-left: 3%; margin-top: 5%;display: flex;align-items: center;">
          <img style="width: 40px;margin-right: 12px;" :src="item.src"/>
          <div>
            <div style="font-size: 14px; color: rgba(0,0,0,0.65);">{{ item.name }}</div>
            <div style="font-size: 36px; font-weight: 700; color: #1F1F1F;">{{toThousandsSeparator(item.num)}}</div>
          </div>
        </div>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <operationAm1  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'operationAm1',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function() {
        return { w: 6, h: 6 }
      }
    },
    option: {
      type: Object,
      default: function() {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function(newVal,oldVal) {
        this.$nextTick(_=>{
          this.getOperateWidth()
          var dom = this.$refs['chart1']
          if(!!dom) {
            dom.handleWindowResize()
          }
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function(newVal,oldVal) {
        if(newVal) {
          if(this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentSelectDeptInfo.CODE": {
      handler: function(newVal,oldVal) {
        if(oldVal) {
          this.getData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      getZEdata: JSON.parse(sessionStorage.getItem('currentDataAll')) || {},
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中
      newData:[
        {
          name: this.commonLangHandler('operationAm1_thisYear' , '本年累计工单', this.getZEdata),
          num:"",
          src:require('@/assets/imgs/img1.png')
        },
        {
          name: this.commonLangHandler('operationAm1_thisMonth' , '本月累计工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          num:"",
          src:"@/assets/imgs/img2.png"
        },
        {
          name: this.commonLangHandler('operationAm1_today' , '今日累计工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          num:"",
          src:"@/assets/imgs/img3.png"
        },
        {
          name:this.commonLangHandler('operationAm1_overdue' , '逾期工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          num:"",
          src:"@/assets/imgs/img4.png"
        },
        {
          name: this.commonLangHandler('operationAm1_unstarted' , '未启动工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          num:"",
          src:"@/assets/imgs/img5.png"
        },
        {
          name: this.commonLangHandler('operationAm1_startedIncomplete' , '已启动未完成工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
          num:"",
          src:"@/assets/imgs/img6.png"
        },

      ]
    }
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if(!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth () {
      if(!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if(!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function(value) {
      if(!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },
    //每月报警预警数
    getData(){
      this.$axios({
        url: Config.dev_url + "/api-apps/WorkOrder/orderDisplay",
        method:"post",
        data:{equipment: this.currentSelectDeptInfo.CODE }
      }).then(res=>{
        let result = res.data
        console.log(result)
        this.newData = [
          {
            name:this.commonLangHandler('operationAm1_thisYear' , '本年累计工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num: result.thisYear,
            src:require('@/assets/imgs/img1.png')
          },
          {
            name: this.commonLangHandler('operationAm1_thisMonth' , '本月累计工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num: result.thisMonth,
            src:require('@/assets/imgs/img2.png')
          },
          {
            name: this.commonLangHandler('operationAm1_today' , '今日累计工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num:  result.thisDay,
            src:require('@/assets/imgs/img3.png')
          },
          {
            name:this.commonLangHandler('operationAm1_overdue' , '逾期工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num: result.yq,
            src:require('@/assets/imgs/img4.png')
          },
          {
            name: this.commonLangHandler('operationAm1_unstarted' , '未启动工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num:result.wqd,
            src:require('@/assets/imgs/img5.png')
          },
          {
            name: this.commonLangHandler('operationAm1_startedIncomplete' , '已启动未完成工单', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            num: result.yqd,
            src:require('@/assets/imgs/img6.png')
          },

        ]
      })
    }


  },
  mounted() {
    this.getOperateWidth()
    if(!this.option.visible) {
      this.getData()
    }
  }

}
</script>

<style lang="less" scoped>
.operationAm1 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div{
      width: 100%;
      height: 100%;
    }
  }
  .card-content2 {
    height: 142px;
    width: 100%;
    .subTable {
      height: 100px;
      display: flex;
      flex-direction: column;
      .subTable-list {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 44px;
        margin-top: 8px;
        box-shadow: 0px -1px 0px 0px #ebebeb inset;
        &:last-child {
          box-shadow: none;
        }
        span {
          display: flex;
        }
        i {
          width: 4px;
          height: 36px;
          margin-right: 6px;
          display: inline-block;
        }

        .percent-box {
          padding: 0 7px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
          text-align: center;
          border-radius: 10px;
          box-sizing: border-box;
          i {
            display: inline-block;
            margin-right: 15px;
          }
        }
        .up {
          background: #fdeaeb;
          color: #f03040;
        }
        .decline {
          background: #e8f5ea;
          color: #00B42A;
        }
      }
    }



  }
}
.textContant{
  cursor: pointer;
}
</style>
<style>
.tooltip_tips{
  border-style: double;
  border-color: #c6c4c4;
  border-width: 1px;
  background: rgb(245 244 244) !important;
  color: rgb(0, 0, 0) !important;
}
</style>
